import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom'
import { activeMenuOption,callService,pageLoadOption,alertOption,redirect } from '../../Utils';
import { API_URL } from '../../constants/api';


const initialState = {
                        title: '',
                        description: '',
                        send_to: 3
                    };

const Read = (props) => {
    const edit = (props.match.params.id ? true : false );
    const [created, setCreated] = useState('');
    const [inputs, setInputs] = useState(initialState);
    const [errors, setErrors] = useState({title:false,description:false,send_to:false});
    const [msg, setMsg] = useState({title:'',description:'',image:'',send_to:''});
    
    const [submitted, setSubmitted] = useState(false);
    const { title, description, send_to } = inputs;
    
    useEffect(async()=>{
        
    },[]);
    
    const getItemData = async()=>{
        pageLoadOption.load(true);
        const response = await callService(`${API_URL.NOTIFICATION_API}${props.match.params.id}`,'GET');
        if(response.success !== undefined && response.success){
            const item = response.item;
            setInputs({title:item.title,description:item.description,send_to:item.send_to});
            setCreated(item.created_at);
        }
        pageLoadOption.load(false);
    }
    const activeMenu = (menu) =>{
        activeMenuOption.setCurrentmenu(menu);
    }
    const handleChange = (e)=> {
        const { name, value } = e.target;
        //console.log(name, value);
        setInputs(inputs => ({ ...inputs, [name]: value }));
        if(value!=''){
            setFormError(name,false);
        }
    }
    const setFormError = (name,value)=> {
        //console.log(name, value);
        setErrors(inputs => ({ ...inputs, [name]: value }));
    }
    
    const validateFrm = ()=> {
        let err = false;
        if(title==''){
            setFormError(`title`,true);
            err = true;
        }
        if(description==''){
            setFormError(`description`,true);
            err = true;
        }
        if(send_to==''){
            setFormError(`send_to`,true);
            err = true;
        }
        return !err;
    }
    const handleSubmit = async(e)=> {
        e.preventDefault();
        
        if (validateFrm()) {
            setSubmitted(true);
            await addItem();
        }
    }
    const addItem = async() =>{
        let params = {title,description,send_to};
        // if(subCat || parent_id>0){
        //     params['parent_id'] = parent_id;
        // }
        //name, description, image, parent_id
        let url = API_URL.NOTIFICATION_ADD_API;
        let method = 'POST';
        if(edit){
            url = `${API_URL.NOTIFICATION_UPDATE}${props.match.params.id}`;
            method = 'PATCH';
            params['created'] = created;
        }
        const response = await callService(url,method,params);
        //console.log(response,"api call response ");
        if(response.success !== undefined && response.success){
            setInputs(initialState);
            //page load
            pageLoadOption.load(true);
            //set alert message info
            alertOption.setMsg({show:true,type:'success',message:response.message});
            //set Current Menu 
            activeMenu(`notification`);
            //redirect on listing page
            redirect('/notification',props.history);
        }else{
            alertOption.setMsg({show:true,type:'danger',message:response.message});
            setSubmitted(false);
        }
    }

    return (
      <>
    <section className="content-header">
        <h1>Message Thread</h1>
    </section>
    <section className="content">
        <div className="row">
            <div className="col-md-12">
                <div className="box box-primary">
                    <div className="box-header with-border">
                        <h3 className="box-title"></h3>
                        <div className="box-tools">
                            <div className="input-group input-group-sm">
                                <Link to="/messages" onClick={()=>activeMenu(`messages`)} className="btn btn-sm btn-primary">
                                <span className="fa fa-backward"></span>&nbsp;&nbsp;Back To Listing
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="box-body">
                        <div className="tab-content">
                        <div className="direct-chat-messages">
                            <div className="direct-chat-msg">
                                <div className="img_section">
                                    <img src="/img/user2-160x160.jpg" alt="image" className="img-circle direct-chat-img" width="50px" height="50px"/>
                                </div>
                                <div className="msg_section">
                                    <div className="direct-chat-info pull-left">
                                        <span className="direct-chat-timestamp pull-right">01 Jan 1970 12:00 AM</span>
                                        <span className="direct-chat-name pull-right">George Barbas</span>
                                    </div>
                                    <div className="direct-chat-text pull-left">
                                        Good morning the wonderful people of CIA.

                                        Let's everyone have a great week and kick off with a quick reflection survey and listing of the goals that you seek to achieve for this week.

                                        Just a note that many of you may not know. Just click on the X sign to change it to a tick mark which tells the system that you achieved that goal.

                                        If there are questions, please do not hesitate to contact me by email george@coachesave.com or my mobile is 0420 440 386

                                        Have a great day everyone.                        
                                    </div>
                                </div>
                            </div>
                            <div className="direct-chat-msg right">
                                <div className="img_section">
                                    <img src="/img/user2-160x160.jpg" alt="image" className="img-circle direct-chat-img" width="50px" height="50px"/>                      
                                </div>
                                <div className="msg_section">
                                    <div className="direct-chat-info pull-right">
                                        <span className="direct-chat-timestamp pull-right">01 Jan 1970 12:00 AM</span>
                                        <span className="direct-chat-name pull-right">George Barbas</span>
                                    </div>
                                    <div className="direct-chat-text pull-right">
                                    We'll have improved in the last week?
                                    Please log in and complete your survey and set your goals for the coming week.
                                    Also remember to tick on the cross to record an achieved goal.
                                    Let's have a great week.
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="box-footer hide">
                        <form method="post" className="form-horizontal form-label-left" autocomplete="off">
                            <div className="input-group">
                                <div className="input text required">
                                    <input type="text" name="message" placeholder="Type Your Message Here ..." id="threadMsg" className="form-control" required="required"/>
                                </div>
                                <span className="input-group-btn">
                                    <button className="btn btn-brown btn-flat" type="submit">Send</button>
                                </span>
                            </div>
                        </form>
                    </div>                    
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default (Read);