import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom'
import ReactPaginate from 'react-paginate';
import { activeMenuOption,callService,pageLoadOption,alertOption,objectToQueryString } from '../../Utils';
import { API_URL } from '../../constants/api';
import { PAGES_TITLE } from '../../constants';

const filterInit = {name:'',parent:0}
const Category = (props) => {
    const pageType = props.match.path.replace('/',"");
    const [pageLoad, setPageLoad] = useState(true);
    const [initLoad,setInitLoad] = useState(0);
    const [imgUrl,setImgUrl] = useState('');
    const [pagination,setPagination] = useState({pageCount:0,initialPage:0});
    const [catItems, setCatItems] = useState([]);
    const [subCatItems, setSubCatItems] = useState([]);
    const [parentItem, setParentItem] = useState([]);
    const [filter,setFilter] = useState(filterInit);
    const { name,parent } = filter;
    useEffect(async()=>{
        activeMenuOption.setPageTitle(PAGES_TITLE[pageType])
        await fetchData();
    },[])
    const deleteItem = async({id,created},type=0)=>{
        let msg = !type ? `Are you sure to delete the item with all child sub category?` : `Are you sure to delete the item?`;
        if(window.confirm(msg)){
            pageLoadOption.load(true);
            const response = await callService(`${API_URL.CAT_EDIT}${id}?create=${created}`,'DELETE');    
            if(response.success !== undefined && response.success){
                await fetchData();
                alertOption.setMsg({show:true,type:'success',message:response.message});
            }else{
                //set alert message info
                alertOption.setMsg({show:true,type:'error',message:response.message});
            }
            setFalseAll();
        }
    }
    const statusUpdate = async({id,created,status},type=0)=>{
        let msg = status ? `Are you sure to deactivate the item?` : `Are you sure to activate the item?`;
        if(window.confirm(msg)){
            pageLoadOption.load(true);
            let url = `${API_URL.CAT_UPDATE}${id}`;
            let method = 'PATCH';
            let params = {created:created,status: status ? 0 : 1};
            const response = await callService(url,'PATCH',params);    
            if(response.success !== undefined && response.success){
                catItems.map(item=>{ if(id==item.id){item.is_activate = !status;} return item;});
                alertOption.setMsg({show:true,type:'success',message:response.message});
            }else{
                //set alert message info
                alertOption.setMsg({show:true,type:'error',message:response.message});
            }
            setFalseAll();
        }
    }
    const setFalseAll = ()=>{
        setPageLoad(false);
        pageLoadOption.load(false);
    }
    const updateHistory = (queryString)=>{
        props.history.push({
                            pathname: props.match.path,
                            search: Object.keys(queryString).length>0?"?" + queryString:''
                            })
    }
    const nextPageRecord = async(event)=>{
        if(event.selected > 0){
            setPagination({...pagination,initialPage:event.selected})
            await fetchData({page:(event.selected + 1)});
        }else{
            await fetchData();
        }
    }
    const fetchData = async(queryString = {}) =>{
        pageLoadOption.load(true);
        //console.log([...props.location.search]);
        /*const queryParams = new URLSearchParams(props.location.search);
        for (const [key, value] of queryParams) {

          console.log({ key, value }," gopal sharna ") // {key: 'term', value: 'pizza'} {key: 'location', value: 'Bangalore'}

        }*/
        let url = pageType=='categories' ? API_URL.CAT_LIST_API : API_URL.SUB_CAT_LIST_API;
        let qryString = {};
        //let page = 0;
        /*if(page>0){
            page = new URLSearchParams(props.location.search).get('page');
            if(page>1){
                queryString['page'] = page;
            }
        }*/
        if(Object.keys(queryString).length>0){
            qryString = objectToQueryString(queryString);
            url += '?'+qryString;
        }

        updateHistory(qryString)
        
        const response = await callService(url,'GET');
        if(typeof response !== undefined && typeof response.success !== undefined && response.success){
            const {categories,url,parentCat,Pagination} = response.data;
            setCatItems(categories);
            setParentItem(parentCat);
            setImgUrl(url);
            setFalseAll();
            setPagination({...pagination,pageCount:Pagination.last_page,initialPage:(Pagination.current_page-1)})
        }else{
            setPageLoad(false);
        }
    }
    const activeMenu = (menu) =>{
        activeMenuOption.setCurrentmenu(menu);
    }
    const handleChange = (e)=> {
        const { name, value } = e.target;
        setFilter(inputs => ({ ...inputs, [name]: value }));
    }
    const searchQueryString = (searchFilter)=>{
        let queryString = {};
        
        if(searchFilter.name != ''){
            queryString['name'] = searchFilter.name;
        }
        if(searchFilter.parent > 0){
            queryString['parent'] = searchFilter.parent;
        }
        return queryString;
    }
    const reset = async () => {
        if(name != '' || parent != ''){
            setFilter(inputs => ({ ...inputs, name: '',parent:'' }));
            await fetchData({});
        }
    }
    const handlerSearch = async(e)=> {
        e.preventDefault();
        let queryString = {};
        queryString = searchQueryString(filter);
        if (Object.keys(queryString).length > 0) {
            await fetchData(queryString);
        }
    }
    return (
      <>
    <section className="content-header">
    <h1>{pageType=='categories'?'Categories':'Sub Categories'}</h1>
    </section>
    <section className="content">
    <div className="row">
        <div className="col-md-12">
        <div className="box box-black">
            <div className="box-header with-border">
                <form onSubmit={handlerSearch}>
                    <div className="pull-left">&nbsp;&nbsp;</div>
                    <div className="input-group input-group-sm pull-left">
                        <div className="input text">
                            <input type="text" name="name" className="form-control pull-right search" placeholder="Search by Name" value={name} onChange={(e)=>handleChange(e)} id="name" />
                        </div>
                    </div>
                    {pageType!='categories' && <>
                    <div className="pull-left">&nbsp;&nbsp;</div>
                    <div className="input-group input-group-sm pull-left">
                        <div className="input select">
                            <select value={parent} name="parent" className="form-control pull-right search" tabIndex="-1" aria-hidden="true" onChange={(e)=>handleChange(e)}>
                                <option value="">Select Parent</option>
                                {Object.keys(parentItem).map((key)=><option key={key} value={key}>{parentItem[key]}</option>)}
                            </select>
                        </div>
                    </div></>}
                    <div className="pull-left">&nbsp;&nbsp;</div>
                    <div className="input-group input-group-sm pull-left">
                        <button className="btn btn-default">
                            <i className="fa fa-search"></i>
                        </button>&nbsp;
                        <a href="javascript:void(0)" onClick={reset} className="btn btn-primary loader-overlay">Reset</a>
                    </div>
                </form>  
                <div className="pull-right">
                    <div className="box-tools">
                        <div className="input-group input-group-sm">
                        {pageType=='categories'?
                            <Link className="btn btn-sm btn-primary" to="/category/add" onClick={()=>activeMenu('add_category')}>
                                <span className="glyphicon glyphicon-plus"></span>&nbsp;&nbsp;Add Category
                            </Link>
                            :
                            <Link className="btn btn-sm btn-primary" to="/category/add/sub" onClick={()=>activeMenu('add_sub_category')}>
                                <span className="glyphicon glyphicon-plus"></span>&nbsp;&nbsp;Add Sub-Category
                            </Link>}
                        </div>
                    </div>
                </div>      
            </div>
            <div className="box-body">
                <table className="table table-striped table-bordered TblAlignedCentered">
                <thead>
                    <tr>
                        <th width="12%"></th>
                        <th width="12%">#</th>
                        {pageType!='categories' && <th>Parent</th>}
                        <th>Image</th>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Created</th>
                    </tr>
                </thead>
                {pageLoad && 
                <tbody>
                <tr>
                    <td colSpan="6" className="text-center">
                        <i className="fa fa-spin fa-refresh"></i>
                    </td>
                </tr>
                </tbody>}
                {!pageLoad && <tbody>
                    {catItems.length>0  ?
                    catItems.map((item,key)=>{
                        return (
                            <tr key={key}>
                        <td>
                            <div className="input-group-btn">
                                <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                    Action &nbsp;&nbsp;
                                    <span className="fa fa-caret-down"></span>
                                </button>
                                <ul className="dropdown-menu">
                                    <li>
                                        <Link to={"/category/edit/"+item.id} className="loader-overlay">
                                            Edit
                                        </Link>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);" onClick={async()=>statusUpdate({id:item.id,created:item.created_at,status:item.is_activate},item.parent_id)} className="loader-overlay">
                                            {item.is_activate?`Deactivate`:`Activate`}
                                        </a>
                                    </li>
                                    {/* <li className="divider"></li>
                                    <li>
                                        <a href="javascript:void(0);" onClick={async()=>deleteItem({id:item.id,created:item.created_at},item.parent_id)} className="loader-overlay">
                                            Delete
                                        </a>
                                    </li> */}
                                </ul>
                            </div>
                        </td>
                        <td>{key+1}</td>
                        {pageType!='categories' && <td>{parentItem[item.parent_id]}</td>}
                        <td>
                            <img src={item.image ? imgUrl+'/'+item.image : "/img/not-found.jpg"} className="img-circle" alt={item.cat_name} width="50" height="50" />
                        </td>
                        <td>{item.cat_name}</td>
                        <td><span className={item.is_activate?"label label-success":"label label-danger"}>{item.is_activate?`Activate`:`Deactivate`}</span></td>
                        <td>{(new Date(item.created_at)).toISOString().slice(0, 10)}</td>
                    </tr> );}) :
                        <tr>
                        <td colSpan="6" className="text-center">No Record found.</td>
                        </tr>
                    }
                    </tbody>}
                </table>
                <div className="clearfix">
                    <div className="text-center">
                <div className="paginate-count pull-left">Total Records : {catItems.length}</div>
                    </div>
                    {pagination.pageCount > 1 && 
                    <ReactPaginate
                        previousLabel={"« previous"}
                        nextLabel={"next »"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pagination.pageCount}
                        forcePage={pagination.initialPage}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={async(event)=>await nextPageRecord(event)}
                        containerClassName={"pagination pagination-sm no-margin pull-right"}
                        subContainerClassName={""}
                        activeClassName={"active"}/>
                    }
                    {/* <ul className="pagination pagination-sm no-margin pull-right">
                        <li><a href="#">«</a></li>
                        <li><a href="#">1</a></li>
                        <li><a href="#">2</a></li>
                        <li><a href="#">3</a></li>
                        <li><a href="#">»</a></li>
                    </ul> */}
                        
                </div>
            </div>
        </div>
        </div>
    </div>
    </section>
    </>
  )
}

export default (Category)