import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom'
import { activeMenuOption,callService,pageLoadOption,alertOption,redirect } from '../../Utils';
import { API_URL } from '../../constants/api';


const initialState = {
                        name: '',
                        description: '',
                        image: '',
                        parent_id: ''
                    };

const Add = (props) => {
    const edit = (props.match.params.id ? true : false );
    const [subCat, setSubCat] = useState(props.match.params.type ? 1 : 0);
    const [created, setCreated] = useState('');
    const [imgUrl, setImgUrl] = useState('');
    const [showImage, setShowImage] = useState('');
    //console.log(match.params,subCat," test demo ")
    const [catList, setCatList] = useState([]);
    const [inputs, setInputs] = useState(initialState);
    const [errors, setErrors] = useState({name:false,description:false,image:false,parent_id:false});
    const [msg, setMsg] = useState({name:'',description:'',image:'This field is mandatory',parent_id:''});
    
    const [submitted, setSubmitted] = useState(false);
    const { name, description, image, parent_id } = inputs;
    
    useEffect(async()=>{
        if(subCat){
            await getParentCat();
        }
        if(edit){
            await getItemData();
        }
    },[]);
    useEffect(async() => {
        let sub = props.match.params.type?1:0;
        setSubCat(sub);
        if(sub){
            await getParentCat();
        }
      }, [props.match.params.type]);
    
    const getItemData = async()=>{
        pageLoadOption.load(true);
        //name, description, image, parent_id
        const response = await callService(`${API_URL.CAT_EDIT}${props.match.params.id}`,'GET');
        if(response.success !== undefined && response.success){
            const {category,url} = response.data;
            setInputs({name:category.cat_name,description:category.cat_description,parent_id:category.parent_id,image:category.image});
            setShowImage(category.image);
            setImgUrl(url)
            setCreated(category.created_at);
            setSubCat(category.parent_id>0?1:0);
            if(category.parent_id>0){
                await getParentCat();
            }
        }
        pageLoadOption.load(false);
    }
    const getParentCat = async()=>{
        pageLoadOption.load(true);
        const response = await callService(API_URL.PARENT_CAT_LIST_API,'GET');
        //console.log(response,"api call response ");
        if(response.success !== undefined && response.success){
            setCatList(response.data.categoryList);
        }
        pageLoadOption.load(false);
    }
    const activeMenu = (menu) =>{
        activeMenuOption.setCurrentmenu(menu);
    }
    const handleChange = (e)=> {
        const { name, value } = e.target;
        //console.log(name, value);
        setInputs(inputs => ({ ...inputs, [name]: value }));
        if(value!=''){
            setFormError(name,false);
        }
    }
    const setFormError = (name,value)=> {
        //console.log(name, value);
        setErrors(inputs => ({ ...inputs, [name]: value }));
    }
    const setFormMsgError = (name,value)=> {
        //console.log(name, value);
        setMsg(inputs => ({ ...inputs, [name]: value }));
    }
    
    const getBase64 = (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    const onFileChange = event => {
        const value = event.target.files[0];
        if(value == ''){
            setFormError(`image`,true);
            setFormMsgError(`image`,'This Field is mandatory');
        }else if(parseFloat((value.size/1024)/1024) > 1){
            setFormError(`image`,true);
            setFormMsgError(`image`,'Image should be less than 1 MB');
        }else{
            setFormError(`image`,false);
            setInputs(inputs => ({ ...inputs, image: value }));
        }
    }
    const validateFrm = ()=> {
        let err = false;
        if(name==''){
            setFormError(`name`,true);
            err = true;
        }
        if(description==''){
            setFormError(`description`,true);
            err = true;
        }
        if(!edit){
            if(image==''){
                setFormError(`image`,true);
                err = true;
            }
        }
        if(subCat || catList.length>0){
            if(parent_id==''){
                setFormError(`parent_id`,true);
                err = true;
            }
        }
        return !err;
    }
    const handleSubmit = async(e)=> {
        e.preventDefault();
        
        if (validateFrm()) {
            if(subCat){
                setSubmitted(true);
            }else{
                setSubmitted(true);
            }
            await addCat();
        }
    }
    const addCat = async() =>{
        let params = {name,description};
        if(subCat || parent_id>0){
            params['parent_id'] = parent_id;
        }
        if(image){
            params['image'] = image;   
        }
        //name, description, image, parent_id
        let url = API_URL.CAT_ADD_API;
        let method = 'POST';
        if(edit){
            url = `${API_URL.CAT_UPDATE}${props.match.params.id}`;
            method = 'PATCH';
            params['created'] = created;
            if(showImage != ''){
                params['oldImage'] = showImage;
            }
        }
        const response = await callService(url,method,params,null,true);
        if(response.success !== undefined && response.success){
            setInputs(initialState);
            //page load
            pageLoadOption.load(true);
            //set alert message info
            alertOption.setMsg({show:true,type:'success',message:response.message});
            //set Current Menu 
            activeMenu(`category`);
            //redirect on listing page
            redirect('/categories',props.history);
        }else{
            alertOption.setMsg({show:true,type:'danger',message:response.message});
            setSubmitted(false);
        }
    }

    return (
      <>
    <section className="content-header">
        <h1>{edit?'Update':'Add'} {subCat ? `Sub-`:''}Category</h1>
    </section>
    <section className="content">
        <div className="row">
            <div className="col-md-12">
            <div className="box box-primary">
                <div className="box-header with-border">
                    <h3 className="box-title"></h3>
                    <div className="box-tools">
                        <div className="input-group input-group-sm">
                            {(edit || !subCat) ? <><Link to="/category/add/sub" onClick={()=>activeMenu(`sub_category`)} className="btn btn-sm btn-primary">
                            <span className="fa fa-plus"></span>&nbsp;&nbsp;Add SubCategory
                            </Link>&nbsp;&nbsp;</>:''}
                            {(edit || subCat) ? <><Link to="/category/add" onClick={()=>activeMenu(`category_add`)} className="btn btn-sm btn-primary">
                            <span className="fa fa-plus"></span>&nbsp;&nbsp;Add Category
                            </Link>&nbsp;&nbsp;</>:''}
                            <Link to={(subCat) ? "/sub_categories" : "/categories"} onClick={()=>activeMenu((subCat) ? "subcategory" : "category")} className="btn btn-sm btn-primary">
                            <span className="fa fa-backward"></span>&nbsp;&nbsp;Back To Listing
                            </Link>
                        </div>
                    </div>
                </div>
                <form className="form-horizontal" onSubmit={handleSubmit}>
                    <div className="box-body">
                        { (subCat || (edit && catList.length>0 )) && 
                        <div className={errors.parent_id ? "form-group has-error":"form-group"}>
                            <label htmlFor="parent_id" className="col-sm-2 control-label">Parent Category</label>
                            <div className="col-sm-7">
                                <select name="parent_id" value={parent_id} className="form-control is-invalid" id="parent_id" onChange={handleChange}>
                                    <option value="">Select One</option>
                                    {catList.map(item=><option value={item.key}>{item.value}</option>)}
                                </select>
                                {errors.parent_id && <span className="help-block">This field is mandatory.</span>}
                            </div>
                            {/*<span className="help-block d-block col-sm-offset-2 col-sm-10">Help block with error</span>*/}
                        </div>}
                        <div className={errors.name ? "form-group has-error":"form-group"}>
                            <label htmlFor="name" className="col-sm-2 control-label">Name</label>
                            <div className="col-sm-7">
                                <input type="text" className="form-control is-invalid" name="name" id="name" placeholder="Name" value={name} onChange={handleChange}/>
                                {errors.name && <span className="help-block">This field is mandatory.</span>}
                            </div>
                            {/*<span className="help-block d-block col-sm-offset-2 col-sm-10">Help block with error</span>*/}
                        </div>
                        <div className={errors.description ? "form-group has-error":"form-group"}>
                            <label htmlFor="description" className="col-sm-2 control-label">Description</label>
                            <div className="col-sm-7">
                                <textarea className="form-control" name="description" value={description} id="description" placeholder="Description" onChange={handleChange}>{description}</textarea>
                                {errors.description && <span className="help-block">This field is mandatory.</span>}
                            </div>
                        </div>
                        <div className={errors.image ? "form-group has-error":"form-group"}>
                            <label htmlFor="image" className="col-sm-2 control-label">Image</label>
                            <div className="col-sm-7">
                                <input type="file" className="form-control" id="image" onChange={onFileChange} />
                                {errors.image && <span className="help-block">{msg.image}.</span>}
                            </div>
                        </div>
                        {showImage && 
                        <div className="form-group">
                            <label className="col-sm-2 control-label"></label>
                            <div className="col-sm-7">
                                <img src={imgUrl+'/'+showImage} width="100" height="100"/>
                            </div>
                        </div>}
                    </div>
                    <div className="box-footer">
                        <div className="form-group">
                            <div className="col-sm-offset-2 col-sm-10">
                                <button type="submit" className="btn btn-primary">
                                {submitted && (<>
                                        <i className="fa fa-spin fa-refresh"></i>&nbsp;&nbsp;
                                    </>)}
                                    Submit
                                </button>&nbsp;&nbsp;  
                                <Link to="/categories" onClick={()=>activeMenu('category')} className="btn btn-default">Cancel</Link>    
                            </div>
                        </div>
                    </div>
                    </form>                    
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default (Add);