import { REDUX,initialState } from '../../constants';
import { getState } from '../../localstorage';

const persistedState = getState();

const initState = persistedState.pageLoad ? persistedState.pageLoad : {load:initialState.pageLoad};
//console.log(initialState);
export function pageLoad(state = initState, action) {
    switch (action.type) {
        case REDUX.PAGE_LOAD:
            return {load:action.page};
        default:
            return state;
    }
}