import { useEffect, useState } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { hideAlert, setPageLoad } from '../../redux/actions';
import Header from './Includes/Header';
import Footer from './Includes/Footer';
import SideBar from './Includes/SideBar';
import AjaxLoader from '../reusable/AjaxLoader';
import Alert from '../reusable/Alert';

const Layout = (props)=>{
    const dispatch = useDispatch();
    const {isAuthenticated,isPageLoad,alert} = useSelector(state =>{ 
        return {
                isAuthenticated:state.authentication.loggedIn,
                isPageLoad:state.pageLoad.load,
                alert:state.alertInfo.alert
            };
    });
    
    useEffect(()=>{
        document.body.className += !isAuthenticated ? " login-page" : '';
        if(alert.show){
            setTimeout(() => {
                dispatch(hideAlert());
              }, 5000)
        };
    })
	return (
		<div>
            {!isAuthenticated ? 
            <div className="login-box">
                <div className="login-logo">
                <a href="javascript:void(0)"><b>Roshi</b></a>
                </div>
                {props.children}    
            </div>
            :
            <div className="wrapper">
            <Header />
	        <SideBar />
	        <div className="content-wrapper">
                {props.children}
            </div>
            <Footer />
            </div>}
            <AjaxLoader show={isPageLoad} />
            <Alert show={alert.show} type={alert.type} message={alert.message}/>
	      </div>
	)	
}
export default Layout;