import React,{ useEffect,useState } from 'react'
import {Link} from 'react-router-dom'
import { useSelector } from 'react-redux';
import { activeMenuOption } from '../../../Utils';
import { navigationStack } from '../../../constants';

const SideBar = ()=>{
    const activeMenu = useSelector(state => state.activeMenu.name);
    const user = useSelector(state => state.authentication.user);
    
    const setCurrentActiveMenu = (menuName) => {
        activeMenuOption.setCurrentmenu(menuName);
    }
	return (
        
		<aside className="main-sidebar">
            <section className="sidebar">
                <div className="user-panel">
                    <div className="pull-left image">
                        <img src={user.profile_image ? user.image_path + '/' + user.profile_image : "/img/user2-160x160.jpg"} className="img-circle" alt="User" />
                    </div>
                    <div className="pull-left info">
                        <p>{user.first_name ? user.first_name + ' ' +user.last_name : user.user_name}</p>
                        <a href="#"><i className="fa fa-circle text-success"></i> Online</a>
                    </div>
                </div>
                <ul className="sidebar-menu" data-widget="tree" data-api="tree" data-accordion="1">
                    <li className="header">MAIN NAVIGATION</li>
                    {navigationStack.length > 0 && navigationStack.map((item,index)=>
                    <li className={(item.link == null ? 'treeview' : '')+((item.activate).includes(activeMenu)?' active menu-open':'')} key={index}>
                        <Link to={item.link == null ?"#":"/"+item.link} onClick={()=>{ {item.active !== undefined && setCurrentActiveMenu(item.active)}}}>
                            <i className={"fa fa-"+item.icon}></i>
                            <span>{item.label}</span>
                            {item.subMenu !== undefined && item.subMenu.length>0 &&
                            <span className="pull-right-container">
                                <i className="fa fa-angle-left pull-right"></i>
                            </span>}
                        </Link>
                        {item.subMenu !== undefined && item.subMenu.length>0 &&
                        <ul className="treeview-menu">
                            {item.subMenu.map((sub,index)=>
                            <li className={sub.activate==activeMenu?'active':''}><Link to={"/"+sub.link} onClick={()=>setCurrentActiveMenu(sub.activate)}><i className="fa fa-circle-o"></i> {sub.label}</Link></li>
                            )}
                        </ul>
                        }
                    </li>)}
                    {/*<li className="treeview">
                        <a href="javascript:void(0);">
                            <i className="fa fa-files-o"></i>
                            <span>Layout Options</span>
                            <span className="pull-right-container">
                            <span className="label label-primary pull-right">4</span>
                            </span>
                        </a>
                        <ul className="treeview-menu">
                            <li><Link to="/"><i className="fa fa-circle-o"></i> Top Navigation</Link></li>
                            <li><Link to="/public"><i className="fa fa-circle-o"></i> Boxed</Link></li>
                            <li><Link to="/setting"><i className="fa fa-circle-o"></i> Fixed</Link></li>
                            <li><Link to="/categories"><i className="fa fa-circle-o"></i> Category</Link></li>
                            <li><Link to="/category/add"><i className="fa fa-circle-o"></i> Add Category</Link></li>
                        </ul>
                    </li>
                    <li>
                    <Link to="/public">
                        <i className="fa fa-th"></i> <span>Public</span>
                        <span className="pull-right-container">
                        <small className="label pull-right bg-green">new</small>
                        </span>
                    </Link>
    </li>*/}
                </ul>
            </section>
        </aside> 
	)	
}
export default SideBar;