import { REDUX,initialState } from '../../constants';
import { getState } from '../../localstorage';

const persistedState = getState();

const initState = persistedState.authentication && persistedState.authentication.user ? { loggedIn: true, user:persistedState.authentication.user } : initialState.authentication;
//console.log(initialState);
export function authentication(state = initState, action) {
    switch (action.type) {
        case REDUX.LOGGED_USER:
            return {
                loggedIn: true,
                user: action.user
            };
        case REDUX.LOGOUT_USER:
            return {loggedIn: false};
        default:
            return state
    }
}