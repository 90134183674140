import React, { useState,useEffect } from "react";
import { Link } from 'react-router-dom'
import { useDispatch,useSelector } from 'react-redux';
import { setLoggedUser } from '../../redux/actions';
import { redirect,callService,activeMenuOption,alertOption,pageLoadOption } from '../../Utils';
import { API_URL } from '../../constants/api';
import { PAGES_TITLE } from '../../constants';
import { EditorState,convertToRaw,ContentState  } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const initialErrorState = {
                        title: false,
                        content: false
                    };
const initialMsgState = {
                        title: "This field is mandatory",
                        content: "This field is mandatory"
                    };

const initialState = {
                        title: '',
                        content: EditorState.createEmpty(),
                        created_at:'',
                        id:''
                    };
const Setting = ({match}) => {
  let type = (match.params.type);
  const [formData, setFormData] = useState(initialState);
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState(initialErrorState);
  const [errorMsg, setErrorMsg] = useState(initialMsgState);
  const { title,content,id,created_at } = formData;
  const dispatch = useDispatch();
  const pageTitle = PAGES_TITLE[type];

  useEffect(async() => {
    pageLoadOption.load(true);
    activeMenuOption.setCurrentmenu(type,pageTitle);
    await getItemData();
  }, [type]);

  const handleChange = (e)=> {
      const { name, value } = e.target;
      setFormData(inputs => ({ ...inputs, [name]: value }));
  }
  const onEditorStateChange = (editorState)=>{
    setFormData(inputs => ({ ...inputs, ['content']: editorState })); 
  }
  const changeDraftToHtml = (editorState)=>{
    return draftToHtml(convertToRaw(editorState.getCurrentContent()));
  }
  const changeHtmlToDraft = (html)=>{
    const contentBlock = htmlToDraft(html);
    let editText = initialState.content;
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      editText = EditorState.createWithContent(contentState);
    }
    return editText;
  }
  const getItemData = async()=>{
      //name, description, image, parent_id
      const response = await callService(`${API_URL.SETTING_API}${type}`,'GET');
      if(response.success !== undefined && response.success){
          const {item,url} = response.data;
          setFormData({title:item.details.title,content:changeHtmlToDraft(item.details.content),id:item.id,created_at:item.created_at});
      }
      pageLoadOption.load(false);

  }
  const updateSetting = async(args) =>{
    pageLoadOption.load(true);
    let params = {title,content:changeDraftToHtml(content),id,created_at};
    
    const response = await callService(`${API_URL.SETTING_API}${type}`,'PATCH',params,null,true);
    let alertParam = {show:true,message:response.message};
    if(response.success !== undefined && response.success){
      let { item } = response.data;
      //setFormData({...item,content:changeHtmlToDraft(item.content)})
      //set alert message info
      alertParam['type'] = 'success';
    }else{
      alertParam['type'] = 'danger';          
    }
    alertOption.setMsg(alertParam);
    setSubmitted(false);
    pageLoadOption.load(false);
  }
  const setFormError = (name,value)=> {
      setErrors(inputs => ({ ...inputs, [name]: value }));
  }
  const setFormErrorMsg = (name,value)=> {
      setErrorMsg(inputs => ({ ...inputs, [name]: value }));
  }
  const validateFrm = ()=> {
      let err = false;

      Object.keys(formData).map((key)=>{
        if(formData[key]==''){
          setFormError(key,true);
          err = true;
        }  
      })
      return !err;
  }

  const handleSubmit = async(e)=> {
    e.preventDefault();
    if (validateFrm()) {
      setSubmitted(true);
      await updateSetting();
    }
  }

  return (
        <>
    <section className="content-header">
        <h1>{pageTitle}</h1>
    </section>
    <section className="content">
      <div className="row">
        <div className="col-md-12">
          <div className="box box-primary">
            <div className="box-header with-border">
                <h3 className="box-title">Page</h3>
            </div>
            <div className="box-body">
              <div className="x_content">
                <form method="post" className="form-horizontal form-label-left" onSubmit={handleSubmit}>
                  <div className="box-body">
                    <div className="input required">
                      <div className={errors.title ? "form-group has-error":"form-group"}>
                        <label className="control-label col-md-3 col-sm-3 col-xs-12" for="first_name">Title</label>
                        <div className="col-md-6 col-sm-6 col-xs-12">
                          <input type="text" name="title" value={title} placeholder="Site Title" required="required" className="form-control col-md-7 col-xs-12" onChange={handleChange}/>
                          {errors.title && <span className="help-block">{errorMsg.title}.</span>}
                        </div>
                      </div>
                    </div>
                      <div className="input required">
                        <div className={errors.content ? "form-group has-error":"form-group"}>
                          <label className="control-label col-md-3 col-sm-3 col-xs-12" for="content">Content</label>
                          <div className="col-md-6 col-sm-6 col-xs-12">
                            <Editor
                              editorState={content}
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName form-control"
                              onEditorStateChange={(editorState)=>onEditorStateChange(editorState)}
                            />
                            {errors.content && <span className="help-block">{errorMsg.content}.</span>}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="box-footer">
                      <div className="form-group">
                        <div className="col-md-6 col-sm-6 col-xs-12 col-md-offset-3">
                          <button className="btn btn-primary" type="submit">{submitted && (<>
                                        <i className="fa fa-spin fa-refresh"></i>&nbsp;&nbsp;
                                    </>)}Update</button>&nbsp;&nbsp;
                          <Link to="/dashboard">Cancel</Link>
                        </div>
                      </div>
                    </div>
                </form>   
              </div> 
            </div>                   
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default Setting;