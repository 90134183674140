import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router,Route,Redirect,Switch ,Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Layout from './components/Layouts';
import routes from './route';
import PrivateRoute from './components/PrivateRoute';
import './App.css';

const App = ()=> {
  const isAuthenticated = useSelector(state => state.authentication.loggedIn);
  const isPageLoad = useSelector(state => state.pageLoad.load);
  return (
    <Router>
    <Layout isAuthenticated={isAuthenticated} loaderShow={isPageLoad}>
      <Switch>         
        {routes.map((route, i) => (
          route.auth ? <PrivateRoute
          exact
          key={i}
          path={route.path}
          component={route.component}
          isAuthenticated={isAuthenticated} />
            : <Route key={i}
                  exact
                  path={route.path}
                  render={props => (
                    isAuthenticated ?
                    <Redirect to={{
                        pathname: '/',
                        state: { from: props.location }
                      }} />
                    :
                      // pass the sub-routes down to keep nesting
                    <route.component {...props} routes={route.routes} />
          )}
          />
        ))}
      </Switch>
      
    </Layout>
    </Router>
  );
}
export default App;
