export const PAGES_TITLE = {
                            "login":"Admin Login",
                            "dashoard":"Dashboard",
                            "profile":"My Profile",
                            "change_password":"Change Password",
                            "categories":"Manage Categories",
                            "sub_categories":"Manage Sub-Categories",
                            "add_category":"Add Category",
                            "trainer":"Manage Trainer",
                            "view_trainer":"View Trainer Details",
                            "trainee":"Manage Trainee",
                            "view_trainee":"View Trainee Details",
                            "notification":"Manage Notifications",
                            "notification_add":"Add Notification",
                            "notification_edit":"Update Notification",
                            "sessions":"Manage Sessions",
                            "schedule_sessions":"Manage Schedule Sessions",
                            "site_setting":"Manage Site Settings",
                            'about_us':"About Us",
                            'terms_conditions':"Terms & Conditions",
                            'privacy_policy':"Privacy Policy"
                        };
export const navigationStack = [
                            {
                                label:"Manage Category",
                                link:null,
                                icon:'files-o',
                                activate:['category','subcategory','add_category','add_sub_category'],
                                subMenu:[
                                    {
                                        label:'Categories',
                                        link:'categories',
                                        activate:'category'
                                    },
                                    {
                                        label:'Sub-Categories',
                                        link:'sub_categories',
                                        activate:'subcategory'
                                    },
                                    {
                                        label:'Add Category',
                                        link:'category/add',
                                        activate:'add_category'
                                    },
                                    {
                                        label:'Add Sub-Category',
                                        link:'category/add/sub',
                                        activate:'add_sub_category'
                                    }
                                ]
                            },
                            {
                                label:"Manage Users",
                                link:null,
                                icon:'users',
                                activate:['trainer','trainee'],
                                subMenu:[
                                    {
                                        label:'Trainer Listing',
                                        link:'users/trainer',
                                        activate:'trainer'
                                    },
                                    {
                                        label:'Trainee Listing',
                                        link:'users/trainee',
                                        activate:'trainee'
                                    }
                                ]
                            },
                            {
                                label:"Manage Notifications",
                                link:null,
                                icon:'envelope',
                                activate:['notification','notification_add'],
                                subMenu:[
                                    {
                                        label:'Listing',
                                        link:'notification',
                                        activate:'notification'
                                    },
                                    {
                                        label:'Send Notification',
                                        link:'notification/add',
                                        activate:'notification_add'
                                    }
                                ]
                            },
                            {
                                label:"Manage Sessions",
                                icon:'calendar',
                                link:null,
                                activate:['sessions','schedule_sessions'],
                                subMenu:[
                                    {
                                        label:'Sessions Listing',
                                        link:'sessions',
                                        activate:'sessions'
                                    },
                                    {
                                        label:'Schduled Sessions',
                                        link:'schedule_sessions',
                                        activate:'schedule_sessions'
                                    }
                                ]
                            },
                            {
                                label:"Manage Settings",
                                link:null,
                                icon:'cogs',
                                activate:['site_setting','stripe_setting'],
                                subMenu:[
                                    {
                                        label:'Site Setting',
                                        link:'site_setting',
                                        activate:'site_setting'
                                    },
                                    {
                                        label:'Stripe Setting',
                                        link:'stripe_setting',
                                        activate:'stripe_setting'
                                    }
                                ]
                            },
                            {
                                label:"Manage Static Pages",
                                link:null,
                                icon:'file',
                                activate:['about_us','terms_conditions','privacy_policy'],
                                subMenu:[
                                    {
                                        label:'About Us',
                                        link:'pages/about_us',
                                        activate:'about_us'
                                    },
                                    {
                                        label:'Terms & Conditions',
                                        link:'pages/terms_conditions',
                                        activate:'terms_conditions'
                                    },
                                    {
                                        label:'Privacy Policy',
                                        link:'pages/privacy_policy',
                                        activate:'privacy_policy'
                                    }
                                ]
                            },
                            /*{
                                label:"Manage Messages",
                                link:'messages',
                                icon:'envelope',
                                active:'messages',
                                activate:['messages','message_read']
                            }*/
                        ];
export const REDUX = {
            LOGGED_USER:'LOGGED_USER',
            LOGOUT_USER:'LOGOUT_USER',
            ACTIVE_MENU:'ACTIVE_MENU',
            ALERT_INFO:'ALERT_INFO',
            HIDE_ALERT:'HIDE_ALERT',
            PAGE_LOAD:'PAGE_LOAD' 
        }
export const initialState = {
            authentication:{
                loggedIn: false
            },
            menu:{name:'home'},
            alert:{show:false,message:'',type:'success'},
            pageLoad:false
        }
export const ROLE = {3:"Both Trainer and Trainee",
                    1:"All Trainer",
                    2:"All Trainee"}