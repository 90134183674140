import { combineReducers } from 'redux';

import { authentication } from './user.reducer';
import { activeMenu } from './menu.reducer';
import { alertInfo } from './alert.reducer';
import { pageLoad } from './pageload.reducer';
//import { alert } from './alert.reducer';

const rootReducer = combineReducers({
    authentication,
    activeMenu,
    alertInfo,
    pageLoad
    //alert
});

export default rootReducer;