import { createStore, applyMiddleware } from 'redux';
//import thunkMiddleware from 'redux-thunk';
//import { createLogger } from 'redux-logger';
import rootReducer from '../reducers';

//const loggerMiddleware = createLogger();

//import throttle from 'lodash/throttle';
import { saveState } from "../../localstorage";

//const persistedState = loadState();


const store = createStore(
    rootReducer
    /*,
    applyMiddleware(
        thunkMiddleware,
        loggerMiddleware
    )*/
);
store.subscribe(()=>{
    //console.log("save data in state",store.getState());
    saveState(store.getState());
    //localStorage.setItem('reduxState', JSON.stringify(store.getState()))
  })
// store.subscribe(throttle(() => {
//     console.log("logout",store.getState());
//     saveState(store.getState());
// },1000));

export default store;