import { REDUX ,initialState} from '../../constants';
import { getState } from '../../localstorage';

const persistedState = getState();

const initState = persistedState.activeMenu && persistedState.activeMenu.name ? { name:persistedState.activeMenu.name } : initialState.menu;
//console.log(initialState);
export function activeMenu(state = initState, action) {
    switch (action.type) {
        case REDUX.ACTIVE_MENU:
            return {
                name: action.menu
            };
        default:
            return state;
    }
}