import { initialState } from '../constants/index';

export const getState = (name="state") => {
    try {
        const serializedState = localStorage.getItem("state");
        if(serializedState === null) {
            return initialState;
        }
        //console.log("Get STate",JSON.parse(serializedState));
        return JSON.parse(serializedState);
    } catch (error){
        return initialState;
    }
};

export const saveState = (state,name="state") => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem("state",serializedState);
        //console.log("save STate",state);
        return true;
    } catch (error){
        return false;
    }
};