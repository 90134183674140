import { alertOption } from '../../Utils';
const Alert = (props)=>{
    const closeAlert = ()=>{
        alertOption.hide();
    }
	return (
        <>
        {props.show && 
        <>
        <div className="float-info">
            <div className={`alert alert-${props.type} alert-dismissible`}>
                <button type="button" className="close" data-dismiss="alert" aria-hidden="true" onClick={closeAlert}>×</button>
                <i className={props.type=='success'?"icon fa fa-check":(props.type=='danger' || props.type=='error'?'icon fa fa-ban':`icon fa fa-${props.type}`)}></i>
                {props.message}
            </div>
        </div>
        </>}
        </>
	)	
}
export default Alert;