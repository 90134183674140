import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import DatePicker from "react-datepicker";
import { capitalize,activeMenuOption,callService,pageLoadOption,alertOption,getDate,objectToQueryString,getDateWithFormat,getTime } from '../../Utils';
import { API_URL } from '../../constants/api';
import { PAGES_TITLE } from '../../constants';
import ModalPopup from '../../components/reusable/Modal';

import "react-datepicker/dist/react-datepicker.css";

const filterInit = {name:'',trainer:0,start:'',end:'',user:0}
const minStartDate = new Date();
minStartDate.setMonth(minStartDate.getMonth() - 1);

const Schedule = (props) => {
    const [modal, setModal] = useState({show:false,onClose:()=>{},onSuccess:()=>{},item:null});
    const [rejectMsg, setRejectMsg] = useState("");
    const [pageLoad, setPageLoad] = useState(false);
    const [filter,setFilter] = useState(filterInit)
    const [items, setItems] = useState([]);
    const [users, setUsers] = useState([]);
    const [trainerList, setTrainerList] = useState([]);
    const [sessions, setSessions] = useState([]);
    const [sessionStatus, setSessionStatus] = useState({});
    const [sessionPaymentStatus, setSessionPaymentStatus] = useState({});
    const [pagination,setPagination] = useState({pageCount:0,initialPage:0});
    const { name,trainer,user,start,end } = filter;
    
    
    useEffect(async()=>{
        const params = new URLSearchParams(props.location.search);
        //console.log(props.location.search," query string ",params);
        let updateSearch = {name:params.get('name')?params.get('name'):'',
                            trainer:(params.get('trainer')?params.get('trainer'):0),
                            start:(params.get('start')?params.get('start'):''),
                            end:(params.get('end')?params.get('end'):'')
                            };
        setFilter(updateSearch);
        activeMenu('schedule_sessions');
        await fetchData(searchQueryString(updateSearch));
    },[])

    const handleChange = (e)=> {
        const { name, value } = e.target;
        //console.log(name, value);
        setFilter(inputs => ({ ...inputs, [name]: value }));
    }
    const setDate = (name,value)=>{
        if(name=='start'){
            setFilter(inputs => ({ ...inputs, end: '' }));    
        }
        setFilter(inputs => ({ ...inputs, [name]: getDateWithFormat(value) }));
    }

    const resetSearch = async()=>{
        if(Object.keys(searchQueryString(filter)).length > 0){
            setFilter(filterInit);
            await fetchData();   
        }
    }
    const setFalseAll = ()=>{
        setPageLoad(false);
        pageLoadOption.load(false);
    }
    const fetchData = async(queryString = {}) =>{
        pageLoadOption.load(true);
        //console.log(`${API_URL.USER_LIST_API}${match.params.type}`);
        let url = `${API_URL.SESSION_SCHEDULE_API}`;
        let qryString = {};
        if(Object.keys(queryString).length>0){
            qryString = objectToQueryString(queryString);
            url += '?'+qryString;
        }
        updateHistory(qryString)
        
        const response = await callService(`${url}`,'GET');
        if(response.success !== undefined && response.success){
            const {items,trainerList,sessionList,traineeList,Pagination,session_status,session_payment_status} = response.data;
            setPagination({...pagination,pageCount:Pagination.last_page,initialPage:(Pagination.current_page-1)})
            setItems(items);
            setSessions(sessionList);
            setTrainerList(trainerList);
            setUsers(traineeList);
            setSessionStatus(session_status);
            setSessionPaymentStatus(session_payment_status);
        }
        setFalseAll();
    }
    const activeMenu = (menu) =>{
        activeMenuOption.setCurrentmenu(menu,PAGES_TITLE[menu]);
    }
    const updateHistory = (queryString)=>{
        props.history.push({
                            pathname: props.match.url,
                            search: Object.keys(queryString).length>0?"?" + queryString:''
                            })
    }
    const searchQueryString = (searchFilter)=>{
        let queryString = {};
        
        if(searchFilter.name != ''){
            queryString['name'] = searchFilter.name;
        }
        if(searchFilter.trainer > 0){
            queryString['trainer'] = searchFilter.trainer;
        }
        if(searchFilter.start != ''){
            queryString['start'] = searchFilter.start;
        }
        if(searchFilter.end != ''){
            queryString['end'] = searchFilter.end;
        }

        return queryString;
    }
    const handlerSearch = async(e)=> {
        e.preventDefault();
        let queryString = {};
        queryString = searchQueryString(filter);
        if (Object.keys(queryString).length > 0) {
            await fetchData(queryString);
        }
    }
    const nextPageRecord = async(event)=>{
        let queryString = {};
        queryString = searchQueryString(filter);
        if(event.selected > 0){
            setPagination({...pagination,initialPage:event.selected})
            queryString['page'] = (event.selected + 1);
            await fetchData(queryString);
        }else{
            await fetchData();
        }
    }
    const setMaxDate = ()=>{
        if(start){
            let current = new Date(start);
            current.setMonth(current.getMonth()+3);
            return current;
        }
        return '';
    }
    const closeModal = ()=>{
        setModal({...modal,show:false,item:null});
        setRejectMsg("");
    }
    const successModal = async()=>{
        if(modal.item != null && rejectMsg.length != 0){
            pageLoadOption.load(true);
            const response = await callService(`${API_URL.SESSION_CANCEL_API}`,'PATCH',{session_schedule_id:modal.item,message:rejectMsg});    
            if(response.success !== undefined && response.success){
                setModal({...modal,show:false,item:null});
                setRejectMsg("");
                await fetchData();
                alertOption.setMsg({show:true,type:'success',message:response.message});
            }else{
                //set alert message info
                alertOption.setMsg({show:true,type:'error',message:response.message});
                setFalseAll();
            }
        }else{
            if(rejectMsg.length != 0){
                alertOption.setMsg({show:true,type:'error',message:"Please provide reject message."});
            }else{
                alertOption.setMsg({show:true,type:'error',message:"Please provide item id."});
            }
        }
    }
    const showModalPopup = (itemId)=>{
        setModal({show:true,onClose:()=>closeModal(),onSuccess:(data)=>successModal(data),item:itemId})
    }

    return (
      <>
    <section className="content-header">
        <h1>Manage Schedule Sessions</h1>
    </section>
    <section className="content">
    <div className="row">
        <div className="col-md-12">
        <div className="box box-black">
        <div className="box-header with-border">

            <form onSubmit={handlerSearch}>
                <div className="pull-left">&nbsp;&nbsp;</div>
                {/*<div className="input-group input-group-sm pull-left">
                                    <div className="input text">
                                        <input type="text" name="name" className="form-control pull-right search" placeholder="Search by Session Name" value={name} onChange={(e)=>handleChange(e)} id="name"/>
                                    </div>
                                </div>
                                <div className="pull-left">&nbsp;&nbsp;</div>
                                <div className="input-group input-group-sm pull-left">
                                    <div className="input select">
                                        <select value={trainer} name="trainer" className="form-control pull-right search" tabIndex="-1" aria-hidden="true" onChange={(e)=>handleChange(e)}>
                                            <option value="">Select Trainer</option>
                                            {Object.keys(users).map((key)=><option key={key} value={key}>{users[key]}</option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className="pull-left">&nbsp;&nbsp;</div>*/}
                <div className="input-group input-group-sm pull-left">
                    <div className="input select">
                        <DatePicker selected={start?new Date(start):''} onChange={(date) => setDate('start',date)}  className="form-control pull-right search" placeholderText="Start Date" dateFormat="yyyy-MM-dd"/>
                    </div>
                </div>
                <div className="pull-left">&nbsp;&nbsp;</div>
                <div className="input-group input-group-sm pull-left">
                    <DatePicker selected={end?new Date(end):''} onChange={(date) => setDate('end',date)}  className="form-control pull-right search" minDate={start?new Date(start):''} maxDate={setMaxDate()} placeholderText="End Date" dateFormat="yyyy-MM-dd"/>
                </div>
                <div className="pull-left">&nbsp;&nbsp;</div>
                <div className="input-group input-group-sm pull-left">
                    <button className="btn btn-default">
                        <i className="fa fa-search"></i>
                    </button>&nbsp;
                    <a href="javascript:void(0)" onClick={()=> resetSearch()} className="btn btn-primary loader-overlay">Reset</a>
                </div>
            </form>        
        </div>
        <div className="box-body">
            <div className="table-responsive1">
            <table className="table table-striped table-bordered TblAlignedCentered no-margin">
                <thead>
                    <tr>
                        <th width="5%"></th>
                        <th width="3%">#</th>
                        <th width="12%">Session Name</th>
                        <th width="15%">User Details</th>
                        <th width="15%">Schedule Date-Time</th>
                        <th width="15%">Payment</th>
                        <th width="12%">Request Status</th>
                        <th width="12%">Payment Status</th>
                        <th width="15%">Created</th>
                    </tr>
                </thead>
                {pageLoad && 
                <tbody>
                <tr>
                    <td colSpan="9" className="text-center">
                        <i className="fa fa-spin fa-refresh"></i>
                    </td>
                </tr>
                </tbody>}
                {!pageLoad && <tbody>
                    {items.length>0  ?
                    items.map((item,key)=>{
                        return (
                            <tr key={key}>
                        <td>
                        
                                                    <div className="input-group-btn">
                                                        <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                                            <span className="fa fa-caret-down"></span>
                                                        </button>
                            {(item.session_status !== sessionStatus.CANCELLED && item.session_status !== sessionStatus.NO_ACTION && item.session_status !== sessionStatus.DISPUT && item.session_status !== sessionStatus.COMPLETED) || (item.session_status == sessionStatus.COMPLETED && item.payment_status !== sessionPaymentStatus.RELEASE) ? 
                                                        <ul className="dropdown-menu">
                                                            <li>
                                                                <a href="javascript:void(0);" onClick={()=>showModalPopup(item.id)} className="loader-overlay">
                                                                    Cancel Session
                                                                </a>
                                                            </li>
                                                        </ul> : <></>}
                                                    </div>
                                                </td>
                        <td>{key+1}</td>
                        <td>{Object.keys(sessions).length > 0 ? sessions[item.session_id] : ''}</td>
                        <td>
                        Trainer : <b> {Object.keys(trainerList).length > 0 ? trainerList[item.trainer_id] : ''}</b><br/>
                        Trainee : <b> {Object.keys(users).length > 0 ? users[item.user_id] : ''}</b>
                        </td>
                        <td>{getDate(parseInt(item.schedule_date),true,false)}<br/>
                        {getTime(parseInt(item.start_time))} - {getTime(parseInt(item.end_time))}</td>
                        <td>
                            Trainee Paid: <i className="fa fa-dollar"></i><b>{parseFloat(item.amount_totrainee).toFixed(2)}</b><br/>
                            Session Fee: <i className="fa fa-dollar"></i><b>{parseFloat(item.amount).toFixed(2)}</b><br/>
                            Trainer Payout: <i className="fa fa-dollar"></i><b>{item.admin_commission != undefined ? parseFloat(item.amount - item.admin_commission).toFixed(2) : parseFloat(item.amount).toFixed(2)}</b><br/>
                            Commission(From Trainer): <i className="fa fa-dollar"></i><b>{item.admin_commission != undefined ? parseFloat((item.admin_commission)).toFixed(2) : 0.00 }</b><br/>
                            Commission(From Trainee): <i className="fa fa-dollar"></i><b>{item.admin_commission_frmtrainee != undefined ? parseFloat((item.admin_commission_frmtrainee)).toFixed(2) : 0.00}</b>
                        </td>
                        <td>
                            {item.session_status == sessionStatus.CREATED && <span className="label label-default">Created</span> }
                            {item.session_status == sessionStatus.NOT_STARTED && <span className="label label-primary">Confirmed</span>}
                            {item.session_status == sessionStatus.IN_PROGRESS && <span className="label label-info">Progress</span>}
                            {item.session_status == sessionStatus.NO_ACTION && <span className="label label-warning">No Action</span>}
                            {item.session_status == sessionStatus.COMPLETED && <span className="label label-success">Completed</span>}
                            {(item.session_status == sessionStatus.CANCELLED || item.session_status == sessionStatus.DISPUT) && <span className="label label-danger">Cancelled</span>}
                            {(item.session_status == sessionStatus.CANCELLED || item.session_status == sessionStatus.DISPUT) &&
                                <>
                                <label>Reason : </label> {item.reject_msg}<br/>
                                <label>Cancelled By : </label> {item.rejected_by == 1 ? "Roshi Admin" : (item.rejected_by == item.user_id ? "Trainee" : "Instructor")}<br/>
                                {item.rejected_at && getDate(parseInt(item.rejected_at))}
                                </>}

                        </td>
                        <td>
                            {/*item.session_status+" - "+sessionStatus.CANCELLED+" = "+sessionStatus.DISPUT +' -- '+item.payment_status + "   -- "+sessionPaymentStatus.NOT_PROCESSED*/}
                            {item.session_status == sessionStatus.NO_ACTION && item.payment_status == sessionPaymentStatus.NOT_PROCESSED && <span className="label label-default">Not Initiate</span>}
                            {(item.session_status !== sessionStatus.CANCELLED && item.session_status !== sessionStatus.NO_ACTION) && item.payment_status == sessionPaymentStatus.NOT_PROCESSED && <span className="label label-default">Pending</span>}
                            {item.payment_status == sessionPaymentStatus.HOLD && <span className="label label-primary">Hold</span>}
                            {item.payment_status == sessionPaymentStatus.REFUND && <span className="label label-info">Refund</span>}
                            {item.payment_status == sessionPaymentStatus.RELEASE && <span className="label label-success">Done</span>}
                            {((item.session_status == sessionStatus.CANCELLED && item.payment_status == sessionPaymentStatus.NOT_PROCESSED) || (item.payment_status == sessionPaymentStatus.CANCEL)) && <span className="label label-danger">Cancel</span>}
                        </td>
                        <td>{getDate(parseInt(item.created_at),true,false,'short')}<br/>{getDate(parseInt(item.created_at),false,true)}</td>
                    </tr> );
                    }) :
                        <tr>
                         <td colSpan="9" className="text-center">No Record found.</td>
                        </tr>
                    }
                    </tbody>}
                </table>
                </div>
            </div>
            <div className="box-footer clearfix">
                <div className="text-center">
                    <div className="paginate-count pull-left">Total Records : {items.length}</div>
                </div>
                {pagination.pageCount > 1 && 
                    <ReactPaginate
                        previousLabel={"« previous"}
                        nextLabel={"next »"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pagination.pageCount}
                        forcePage={pagination.initialPage}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={async(event)=>await nextPageRecord(event)}
                        containerClassName={"pagination pagination-sm no-margin pull-right"}
                        subContainerClassName={""}
                        activeClassName={"active"}/>
                    }
                {/*Object.keys(pagination).length > 0 &&
                <ul className="pagination pagination-sm no-margin pull-right">
                    <li><a href="javascript:void(0);" onClick={()=>paginate(1)}>«</a></li>
                    <li><a href="javascript:void(0);" onClick={()=>paginate(1)}>1</a></li>
                    <li><a href="javascript:void(0);" onClick={()=>paginate(2)}>2</a></li>
                    <li><a href="javascript:void(0);" onClick={()=>paginate(3)}>3</a></li>
                    <li><a href="javascript:void(0);" onClick={()=>paginate(3)}>»</a></li>
                </ul>*/}
            </div>
            <ModalPopup show={modal.show} onClose={()=>modal.onClose()} onSuccess={(data)=>modal.onSuccess(data)}>
                <form className="form-horizontal" >
                    <h4 className="modal-title">Are you sure to cancel this sessin ,if yes the put the reason for cancel?</h4>
                 <div className="box-body">
                 <div className="form-group">
                    <label htmlFor="description" className="col-sm-2 control-label">Reject</label>
                    <div className="col-sm-7">
                        <textarea className="form-control" name="reject" id="reject" placeholder="Reject Message" onChange={(str)=>setRejectMsg(str.target.value)}></textarea>
                    </div>
                </div>
                </div>
                <div className="box-footer">
                    <div className="form-group">
                        <div className="col-sm-offset-2 col-sm-10">
                            <button type="button" className="btn btn-default pull-left" data-dismiss="modal" onClick={()=> closeModal()}>Close</button>&nbsp;&nbsp;  
                            <button type="button" className="btn btn-primary" onClick={()=> successModal()}>Submit</button>
                            {/*<button type="submit" className="btn btn-primary">
                                                        {submitted && (<>
                                                                <i className="fa fa-spin fa-refresh"></i>&nbsp;&nbsp;
                                                            </>)}
                                                            Submit
                                                        </button>&nbsp;&nbsp;  
                                                        <Link to="/categories" onClick={()=>activeMenu('category')} className="btn btn-default">Cancel</Link>    */}
                        </div>
                    </div>
                </div>
                </form>
            </ModalPopup>
        </div>
        </div>
    </div>
    </section>
    </>
  )
}

export default Schedule