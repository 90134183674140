import React, { useState,useEffect } from "react";
import { Link } from 'react-router-dom'
import { useDispatch,useSelector } from 'react-redux';
import { setLoggedUser } from '../../redux/actions';
import { redirect,callService,activeMenuOption,alertOption,pageLoadOption } from '../../Utils';
import { API_URL } from '../../constants/api';
import { PAGES_TITLE } from '../../constants';

const type = `site_setting`;
const initialErrorState = {
                        title: false,
                        email: false,
                        phone:false,
                        commission:false,
                        commission_frmtrainee:false,
                        stripefee_from:false,
                        logo: false
                    };
const initialMsgState = {
                        title: "This field is mandatory",
                        email: "This field is mandatory",
                        phone: "This field is mandatory",
                        commission: "This field is mandatory",
                        commission_frmtrainee: "This field is mandatory",
                        stripefee_from: "This field is mandatory",
                        logo: "This field is mandatory"
                    };

const initialState = {
                        title: '',
                        email: '',
                        phone: '',
                        commission: '0',
                        commission_frmtrainee: '0',
                        stripefee_from: '',
                        logo: '',
                        created_at:'',
                        id:''
                    };
const Setting = () => {
    let selectImage;
    const [formData, setFormData] = useState(initialState);
    const [submitted, setSubmitted] = useState(false);
    const [showImage, setShowImage] = useState('');
    const [newImage, setNewImage] = useState('');
    const [errors, setErrors] = useState(initialErrorState);
    const [imgUrl, setImgUrl] = useState('');
    const [errorMsg, setErrorMsg] = useState(initialMsgState);
    const { title,email,logo,phone,commission,commission_frmtrainee,stripefee_from,id,created_at } = formData;
    const dispatch = useDispatch();

    useEffect(async() => {
      pageLoadOption.load(true);
      activeMenuOption.setCurrentmenu(type,PAGES_TITLE[type]);
      await getItemData();
    }, []);

    const handleChange = (e)=> {
        const { name, value } = e.target;
        setFormData(inputs => ({ ...inputs, [name]: value }));
    }
    const getItemData = async()=>{
        //name, description, image, parent_id
        const response = await callService(`${API_URL.SETTING_API}${type}`,'GET');
        if(typeof response != undefined){
          if(response.success !== undefined && response.success){
              const {item,url} = response.data;
              setFormData({title:item.details.title,email:item.details.email,phone:item.details.phone,commission:item.details.commission,commission_frmtrainee:item.details.commission_frmtrainee,stripefee_from:item.details.stripefee_from,logo:'',id:item.id,created_at:item.created_at});
              setShowImage(item.details.logo);
              setImgUrl(url)
          }
        }
        pageLoadOption.load(false);
    }
    const updateSetting = async(args) =>{
      pageLoadOption.load(true);
      let params = {email,title,id,created_at,phone,commission,commission_frmtrainee,stripefee_from};
      if(logo){
        params['logo'] = logo;   
        setShowImage('');
      }else{
        params['oldLogo'] = showImage;
      }
      const response = await callService(`${API_URL.SETTING_API}${type}`,'PATCH',params,null,true);
      if(typeof response != undefined){
        let alertParam = {show:true,message:response.message};
        if(response.success !== undefined && response.success){
          let { item } = response.data;
          setShowImage(item.details.logo)
          /*if(params['logo'] != undefined){
            setNewImage('') 
          }*/
          item.logo = '';
          // setFormData(item)
          //set alert message info
          alertParam['type'] = 'success';
        }else{
          alertParam['type'] = 'danger';          
        }
        alertOption.setMsg(alertParam);
      }
      setSubmitted(false);
      pageLoadOption.load(false);
    }
    const setFormError = (name,value)=> {
        setErrors(inputs => ({ ...inputs, [name]: value }));
    }
    const setFormErrorMsg = (name,value)=> {
        setErrorMsg(inputs => ({ ...inputs, [name]: value }));
    }
    const validateFrm = ()=> {
        let err = false;

        Object.keys(formData).map((key)=>{
          if(key == 'logo'){
            if(id.length == 0 && formData[key]==''){
              setFormError(key,true);
              err = true;
            }
          }else{
            if(formData[key]==''){
              setFormError(key,true);
              err = true;
            }  
          }
        })
        return !err;
    }

    const onFileChange = event => {
        const value = event.target.files[0];
        if(value == ''){
          setFormError(`logo`,true);
          setFormErrorMsg(`logo`,'This Field is mandatory');
        }else if(parseFloat((value.size/1024)/1024) > 1){
          setFormError(`logo`,true);
          setFormErrorMsg(`logo`,'Image should be less than 1 MB');
          event.target.value = '';
        }else{
          setFormError(`logo`,false);
          setFormData(inputs => ({ ...inputs, logo: value }));
          showNewImage(value);
        }                                   
    }

    const showNewImage = (value)=>{
      var reader = new FileReader();
      var url = reader.readAsDataURL(value);
      reader.onloadend = function (e) {
          setNewImage(reader.result);
      }.bind(this);
    }

    const handleSubmit = async(e)=> {
      e.preventDefault();
      if (validateFrm()) {
        setSubmitted(true);
        await updateSetting();
      }
    }

    return (
        <>
    <section className="content-header">
        <h1>Site Setting</h1>
    </section>
    <section className="content">
      <div className="row">
        <div className="col-md-12">
          <div className="box box-primary">
            <div className="box-header with-border">
                <h3 className="box-title">Setting</h3>
            </div>
            <div className="box-body">
              <div className="x_content">
                <form method="post" className="form-horizontal form-label-left" onSubmit={handleSubmit}>
                  <div className="box-body">
                    <div className="input required">
                      <div className={errors.title ? "form-group has-error":"form-group"}>
                        <label className="control-label col-md-3 col-sm-3 col-xs-12" htmlFor="first_name">Title</label>
                        <div className="col-md-6 col-sm-6 col-xs-12">
                          <input type="text" name="title" value={title} placeholder="Site Title" required="required" className="form-control col-md-7 col-xs-12" onChange={handleChange}/>
                          {errors.title && <span className="help-block">{errorMsg.title}.</span>}
                        </div>
                      </div>
                    </div>
                    <div className="input required">
                      <div className={errors.email ? "form-group has-error":"form-group"}>
                        <label className="control-label col-md-3 col-sm-3 col-xs-12" htmlFor="last_name">Email</label>
                        <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="input-group">
                          <span className="input-group-addon"><i className="fa fa-envelope"></i></span>
                          <input type="text" name="email" value={email} placeholder="Site Email" required="required" className="form-control" onChange={handleChange}/>
                        </div>
                        {errors.email && <span className="help-block">{errorMsg.email}.</span>}
                        </div>
                      </div>
                    </div>
                    <div className="input required">
                      <div className={errors.phone ? "form-group has-error":"form-group"}>
                        <label className="control-label col-md-3 col-sm-3 col-xs-12" htmlFor="phone">Phone</label>
                        <div className="col-md-6 col-sm-6 col-xs-12">
                          <div className="input-group">
                            <span className="input-group-addon"><i className="fa fa-phone"></i></span>
                            <input type="number" name="phone" value={phone} placeholder="Site Phone" required="required" className="form-control" onChange={handleChange}/>
                          </div>
                          {errors.phone && <span className="help-block">{errorMsg.phone}.</span>}
                        </div>
                      </div>
                    </div>
                    <div className="input required">
                      <div className={errors.commission ? "form-group has-error":"form-group"}>
                        <label className="control-label col-md-3 col-sm-3 col-xs-12" htmlFor="commission">Commission From Trainer</label>
                        <div className="col-md-6 col-sm-6 col-xs-12">
                          <div className="input-group">
                            <span className="input-group-addon"><i className="fa fa-percent"></i></span>
                            <input type="number" any=".0" name="commission" value={commission} placeholdr="Commission %" required="required" className="form-control" onChange={handleChange}/>
                          </div>
                          {errors.commission && <span className="help-block">{errorMsg.commission}.</span>}
                        </div>
                      </div>
                    </div>
                    <div className="input required">
                      <div className={errors.commission_frmtrainee ? "form-group has-error":"form-group"}>
                        <label className="control-label col-md-3 col-sm-3 col-xs-12" htmlFor="commission_frmtrainee">Commission From Trainee</label>
                        <div className="col-md-6 col-sm-6 col-xs-12">
                          <div className="input-group">
                            <span className="input-group-addon"><i className="fa fa-percent"></i></span>
                            <input type="number" any=".0" name="commission_frmtrainee" value={commission_frmtrainee} placeholdr="Commission %" required="required" className="form-control" onChange={handleChange}/>
                          </div>
                          {errors.commission_frmtrainee && <span className="help-block">{errorMsg.commission_frmtrainee}.</span>}
                        </div>
                      </div>
                    </div>
                    {/* <div className="input required">
                      <div className={errors.stripefee_from ? "form-group has-error":"form-group"}>
                        <label className="control-label col-md-3 col-sm-3 col-xs-12" for="stripefee_from">Stripe Charges adjust from</label>
                        <div className="col-md-6 col-sm-6 col-xs-12">
                          <select name="stripefee_from" required="required" className="form-control col-md-7 col-xs-12" onChange={handleChange}>
                            <option value="commfrmtrainee" selected={stripefee_from == 'commfrmtrainee' ? 'selected' : '' }>Commission From Trainee</option>
                            <option value="trainerfee" selected={stripefee_from == 'trainerfee' ? 'selected' : '' }>Trainer Fee</option>
                          </select>
                          {errors.stripefee_from && <span className="help-block">{errorMsg.stripefee_from}.</span>}
                        </div>
                      </div>
                    </div> */}
                      <div className="input required">
                        <div className={errors.logo ? "form-group has-error":"form-group"}>
                          <label className="control-label col-md-3 col-sm-3 col-xs-12" htmlFor="logo">Logo</label>
                          <div className="col-md-6 col-sm-6 col-xs-12">
                            <input type="file" className="form-control" id="logo" onChange={onFileChange} />
                            {errors.logo && <span className="help-block">{errorMsg.logo}.</span>}
                          </div>
                        </div>
                      </div>
                      {showImage && 
                        <div className="form-group">
                            <label className="control-label col-md-3 col-sm-3 col-xs-12"></label>
                            <div className="col-sm-7">
                                <img src={imgUrl+'/'+showImage} width="100" height="100"/>
                            </div>
                        </div>}
                    </div>
                    <div className="box-footer">
                      <div className="form-group">
                        <div className="col-md-6 col-sm-6 col-xs-12 col-md-offset-3">
                          <button className="btn btn-primary" type="submit">{submitted && (<>
                                        <i className="fa fa-spin fa-refresh"></i>&nbsp;&nbsp;
                                    </>)}Update</button>&nbsp;&nbsp;
                          <Link to="/dashboard">Cancel</Link>
                        </div>
                      </div>
                    </div>
                </form>   
              </div> 
            </div>                   
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default Setting;