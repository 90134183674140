import React, { useEffect } from 'react';
import {Link} from 'react-router-dom'
import logo from '../logo.svg';
import Login from '../Pages/Auth/Login';
import NotFound from '../components/reusable/NotFound';
import Dashboard from '../Pages/Dashboard';
import Category from '../Pages/Category';
import CategoryAdd from '../Pages/Category/add';
import User from '../Pages/Users';
import Profile from '../Pages/Users/profile';
import ChangePassword from '../Pages/Users/change_password';
import UserDetail from '../Pages/Users/details';
import Notification from '../Pages/Notification';
import NotificationAdd from '../Pages/Notification/add';
import Message from '../Pages/Message';
import MessageRead from '../Pages/Message/read';
import Setting from '../Pages/Settings';
import Stripe from '../Pages/Settings/stripe';

import Sessions from '../Pages/Sessions';
import Schedule from '../Pages/Sessions/schedule';
import StaticPages from '../Pages/StaticPages';

import Transactions from '../Pages/Transactions';

const routes = [
    {
        path: "/",
        component: Dashboard,
        title: "",
        auth:true
    },
    {
      path: "/categories",
      component: Category,
      auth: true
    },
    {
      path: "/sub_categories",
      component: Category,
      auth: true
    },
    {
      path: "/site_setting",
      component: Setting,
      auth: true
    },
    {
      path: "/stripe_setting",
      component: Stripe,
      auth: true
    },
    {
      path: "/category/add/:type?",
      component: CategoryAdd,
      auth: true
    },
    {
      path: "/category/edit/:id",
      component: CategoryAdd,
      auth: true
    },
    {
      path: "/users/:type",
      component: User,
      auth: true
    },
    {
      path: "/change_password",
      component: ChangePassword,
      auth: true
    },
    {
      path: "/profile",
      component: Profile,
      auth: true
    },
    {
      path: "/user/:id",
      component: UserDetail,
      auth: true
    },
    {
      path: "/dashboard",
      component: Dashboard,
      auth:true
    },
    {
      path: "/sessions",
      component: Sessions,
      auth:true
    },
    {
      path: "/schedule_sessions",
      component: Schedule,
      auth:true
    },
    {
      path: "/transactions",
      component: Transactions,
      auth:true
    },
    {
      path: "/notification",
      component: Notification,
      auth: true
    },
    {
      path: "/notification/add",
      component: NotificationAdd,
      auth: true
    },
    {
      path: "/notification/edit/:id",
      component: NotificationAdd,
      auth: true
    },
    {
      path: "/messages",
      component: Message,
      auth: true
    },
    {
      path: "/message/read/:id",
      component: MessageRead,
      auth: true
    },
    {
      path: "/pages/:type",
      component: StaticPages,
      auth: true
    },
    {
        path: "/login",
        component: Login,
        auth:false
    },
    /*
    {
        path: "/register",
        component: Register,
        auth:false
    },
    {
        path: "/forgot",
        component: Forgot,
        auth:false
    },*/
    {
        path: "*",
        component: NotFound
    },

    // {
    //   path: "/tacos",
    //   component: Tacos,
    //   routes: [
    //     {
    //       path: "/tacos/bus",
    //       component: Bus
    //     },
    //     {
    //       path: "/tacos/cart",
    //       component: Cart
    //     }
    //   ]
    // }
  ];

export default routes;