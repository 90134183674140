import React, { useEffect,useState } from "react"
import { Link } from 'react-router-dom'
import { capitalize,activeMenuOption,callService,pageLoadOption,alertOption,getDate } from '../../Utils';
import { API_URL } from '../../constants/api';
import { PAGES_TITLE } from '../../constants';

const UserDetail = ({match}) => {
  const id = (match.params.id );
  const [pageLoad, setPageLoad] = useState(true);
  const [user, setUser] = useState({});
  const [billing, setBilling] = useState({});
  const [mailing, setMailing] = useState({});
  const [categories, setCategories] = useState([]);
  const [cateList, setCateList] = useState({});
  const [imagePath, setImagePath] = useState('');
    
  useEffect(async()=>{
    activeMenuOption.setPageTitle("User Details");
    pageLoadOption.load(true);
    await fetchData();
  },[])

  const setFalseAll = ()=>{
    setPageLoad(false);
    pageLoadOption.load(false);
  }
  const fetchData = async() =>{
    const response = await callService(`${API_URL.USER_API}${id}`,'GET');
    console.log(response,"api user list get ");
    if(response.success !== undefined && response.success){
        const {item,url,myAddress,myCategory} = response.data;
        setUser(item);
        setImagePath(url);
        setBilling(myAddress.billing);
        setMailing(myAddress.mailing);
        setCateList(myCategory.category);
        setCategories(myCategory.items);
        let pageTitle = "view_trainer";
        if(item.user_role==2){
          pageTitle = "view_trainee";
        }
        activeMenuOption.setPageTitle(PAGES_TITLE[pageTitle]);
    }
    setFalseAll();
  }
  const activeMenu = (menu) =>{
    activeMenuOption.setCurrentmenu(menu);
  }
  const addressView = (type,address)=>{
    return (<><h4>{type} Address</h4>
    <hr/>
    <div className="input required">
      <div className="form-group">
        <label className="control-label col-md-3 col-sm-3 col-xs-12">Address</label>
        <div className="col-md-6 col-sm-6 col-xs-12">
          {address.line1 ? address.line1:''} {address.line2 ? address.line2:''}
        </div>
      </div>
    </div>
    <div className="input required">
      <div className="form-group">
        <label className="control-label col-md-3 col-sm-3 col-xs-12">City</label>
        <div className="col-md-6 col-sm-6 col-xs-12">
          {address.city ? address.city:''}
        </div>
      </div>
    </div>
    <div className="input required">
      <div className="form-group">
        <label className="control-label col-md-3 col-sm-3 col-xs-12">State</label>
        <div className="col-md-6 col-sm-6 col-xs-12">
          {address.state ? address.state:''}
        </div>
      </div>
    </div>
    <div className="input required">
      <div className="form-group">
        <label className="control-label col-md-3 col-sm-3 col-xs-12">Country</label>
        <div className="col-md-6 col-sm-6 col-xs-12">
          {address.country ? address.country : '' }
        </div>
      </div>
    </div>
    </>);
  }
  const categoryView = (category)=>{
    return (<tr key={category.index}>
        <td>{category.index}</td>
        <td>{cateList[category.category_id]}</td>
        <td>{cateList[category.sub_category]}</td>
        <td>{category.location}
          {category.landmark != undefined && category.landmark.length > 0 && <br/>}
          category.landmark != undefined && category.landmark.length > 0 && 'Landmark : '+category.landmark}
        </td>
        <td>{category.created_at}</td>
      </tr>);
  }
  let index = 0;
  return (
      <>
    <section className="content-header">
        <h1>User Profile</h1>
        <ol className="breadcrumb">
            <li><Link to={"/dashboard"}><i className="fa fa-dashboard"></i> Home</Link></li>
            <li><Link to={"/users/"+( user.user_role && user.user_role==2 ? 'trainee' : 'trainer' )}>Users</Link></li>
            <li className="active">Details</li>
        </ol>
    </section>
    <section className="content">
  <div className="row">
    <div className="col-md-3">
      <div className="box box-primary">
        <div className="box-body box-profile">
          <img className="profile-user-img img-responsive img-circle" src={user.profile_image ? imagePath + '/'+ user.profile_image : "/img/user2-128x128.jpg"} alt="User profile picture"/>
          <h3 className="profile-username text-center">{user.first_name + ' ' + user.last_name}</h3>
          <ul className="list-group list-group-unbordered">
            <li className="list-group-item">
              <b>Email</b>
              <p className="text-muted">
              {user.email ? user.email : ''}
              </p>
            </li>
            <li className="list-group-item">
              <b>UserName</b>
              <p className="text-muted">
              {user.user_name ? user.user_name : ''}
              </p>
            </li>
            {user.price_prhr != undefined && user.price_prhr != 0 ?
              <li className="list-group-item">
              <b>Rate/Hr</b>
              <p className="text-muted">
              $ {user.price_prhr}
              </p>
            </li> : <></>}
            {user.wallet_amount != undefined && user.wallet_amount != 0 ?
              <li className="list-group-item">
              <b>Wallet Amount</b>
              <p className="text-muted">
              $ {user.wallet_amount}
              </p>
            </li> : <></>}
            <li className="list-group-item">
              <b>Gender</b>
              <p className="text-muted">
              {user.sex ? user.sex : ''}
              </p>
            </li>
            <li className="list-group-item">
              <b>DOB</b>
              <p className="text-muted">
              {user.dob ? (new Date(parseInt(user.dob))).toISOString().slice(0, 10):''}
              </p>
            </li>
            <li className="list-group-item">
              <b>Phone</b>
              <p className="text-muted">
              {user.phone ? user.phone:''}
              </p>
            </li>
            <li className="list-group-item">
              <b>Created</b>
              <p className="text-muted">
              {user.created_at ? getDate(parseInt(user.created_at)):''}
              </p>
            </li>
            <li className="list-group-item">
              <b>Status</b>
              <p>
              {user.deleted_at != undefined && user.deleted_at != null ?
              <>
              <span className="label label-danger">Deleted</span><br/>
              {getDate(parseInt(user.deleted_at))}
              </>:
              <>
              {user.profile_status == 0 &&
              <span className="label label-info">Pending</span>}
              {user.profile_status == 2 &&
              <span className="label label-danger">Rejected</span>}
              {user.profile_status == 1 &&
              <span className="label label-success">Approved</span>}
              </>
              }
              </p>
            </li>
          </ul>
          <Link to={"/users/"+(user.role && user.user_role==2 ? 'trainee' : 'trainer' )} onClick={()=>activeMenu((user.role && user.user_role==2 ? 'trainee' : 'trainer' ))} className="btn btn-primary btn-block">
              <span className="fa fa-backward"></span>&nbsp;&nbsp;Back To Listing
            </Link>
        </div>
      </div>
    </div>
    <div className="col-md-9">
      <div className="nav-tabs-custom">
        <ul className="nav nav-tabs">
          <li className="active"><a href="#address" data-toggle="tab" aria-expanded="true">Address</a></li>
          {user.user_role==1 && 
          <li className=""><a href="#category" data-toggle="tab" aria-expanded="true">Category</a></li>
          }
        </ul>
        <div className="tab-content">
          <div className="tab-pane active" id="address">
            <div className="post">
              <form method="post" className="form-horizontal form-label-left">
                  <div className="box-body">
                    { Object.keys(billing).length > 0 ? addressView("Billing",billing) : <div className="input required">
                      no record found.
                    </div>}
                    { Object.keys(mailing).length > 0 && addressView("Mailing",mailing)}
                  </div>
                </form>
            </div>
          </div>
          {user.user_role==1 && <div className="tab-pane" id="category">
            <div className="post">
              <table className="table table-striped table-bordered TblAlignedCentered no-margin">
                <thead>
                    <tr>
                        <th width="12%">#</th>
                        <th>Category</th>
                        <th>Sub-Category</th>
                        <th>Location</th>
                        <th>Created</th>
                    </tr>
                </thead>
                <tbody>
                  {categories.length > 0 ? 
                    categories.map((items,key)=>{
                      const cateData = {category_id:items.category_id,id:items.id,created_at:items.created_at};
                      return ((items.details).map(item=>{
                        return categoryView({...item,...cateData,index:++index})
                      }));
                    }) : 
                  <tr>
                    <td colspan="5">No Record found.</td>
                  </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>}
        </div>
      </div>
    </div>
  </div>
</section>
    </>
  )
}

export default UserDetail