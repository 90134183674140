import React, { useState,useEffect } from "react";
import { Link } from 'react-router-dom'
import { useDispatch,useSelector } from 'react-redux';
import { setLoggedUser } from '../../redux/actions';
import { redirect,callService,activeMenuOption,alertOption,pageLoadOption } from '../../Utils';
import { API_URL } from '../../constants/api';
import { PAGES_TITLE } from '../../constants';

const initialErrorState = {
                        first_name: false,
                        last_name: false,
                        phone: false,
                        profile_image: false
                    };
const initialMsgState = {
                        first_name: "This field is mandatory",
                        last_name: "This field is mandatory",
                        phone: "This field is mandatory",
                        profile_image: "This field is mandatory"
                    };

const Profile = () => {
    let fileInputRef = React.useRef();
    let auth = useSelector(state => state.authentication.user);
    const initialState = {
                        first_name: auth.first_name,
                        last_name: auth.last_name,
                        phone: auth.phone,
                        profile_image: '' //auth.profile_image
                    };
    const [formData, setFormData] = useState(initialState);
    const [submitted, setSubmitted] = useState(false);
    const [showImage, setShowImage] = useState('');
    const [errors, setErrors] = useState(initialErrorState);
    const [newImage, setNewImage] = useState('');
    const [imgUrl, setImgUrl] = useState('');
    const [errorMsg, setErrorMsg] = useState(initialMsgState);
    const { first_name,last_name,phone,profile_image } = formData;
    const dispatch = useDispatch();

    useEffect(async() => {
      pageLoadOption.load(false);
      activeMenuOption.setCurrentmenu("profile",PAGES_TITLE['profile']);
      await getItemData();
    }, []);

    const handleChange = (e)=> {
        const { name, value } = e.target;
        setFormData(inputs => ({ ...inputs, [name]: value }));
    }
    const getItemData = async()=>{
        setShowImage(auth.profile_image);
        setImgUrl(auth.image_path)
        //name, description, image, parent_id
        /*const response = await callService(`${API_URL.PROFILE_API}`,'GET');
        if(response.success !== undefined && response.success){
            const {user,url} = response.data;
            setFormData({first_name:user.first_name,last_name:user.last_name,phone:user.phone,profile_image:user.profile_image});
            setShowImage(user.profile_image);
            setImgUrl(url)
        }
        pageLoadOption.load(false);*/
    }
    const updateProfile = async(args) =>{
      pageLoadOption.load(true);
      //console.log(fileInputRef,"file input refer ",profile_image)
      let params = {first_name,last_name,phone};
      if(profile_image){
        params['profile_image'] = profile_image;   
        if(showImage != ''){
          params['oldImage'] = showImage;
          setShowImage('');
        }
      }
      const response = await callService(API_URL.PROFILE_API,'PATCH',params,null,true);
      let alertParam = {show:true,message:response.message};
      if(response.success !== undefined && response.success){
        let {user} = response.data;
        auth = {...auth,...user};
        if(params['profile_image'] != undefined){
          setShowImage(newImage);
          setNewImage('');
          fileInputRef.setValue("");
        }
        user.profile_image = '';
        setFormData(user)
        dispatch(setLoggedUser(auth));
        //set alert message info
        alertParam['type'] = 'success';
      }else{
        alertParam['type'] = 'danger';          
      }
      alertOption.setMsg(alertParam);
      setSubmitted(false);
      pageLoadOption.load(false);
    }
    const setFormError = (name,value)=> {
        setErrors(inputs => ({ ...inputs, [name]: value }));
    }
    const setFormErrorMsg = (name,value)=> {
        setErrorMsg(inputs => ({ ...inputs, [name]: value }));
    }
    const validateFrm = ()=> {
        let err = false;

        Object.keys(formData).map((key)=>{
          if(first_name==''){
            setFormError(key,true);
            err = true;
          }  
        })
        return !err;
    }

    const onFileChange = event => {
      const value = event.target.files[0];
      //console.og(value)
      if(value == ''){
        setFormError(`profile_image`,true);
        setFormErrorMsg(`profile_image`,'This Field is mandatory');
      }else if(parseFloat((value.size/1024)/1024) > 1){
        setFormError(`profile_image`,true);
        setFormErrorMsg(`profile_image`,'Image should be less than 1 MB');
        event.target.value = null;
      }else{
        setFormError(`profile_image`,false);
        setFormData(inputs => ({ ...inputs, profile_image: value }));
        showNewImage(value);
      }        
    }
    const showNewImage = (value)=>{
      var reader = new FileReader();
      var url = reader.readAsDataURL(value);
      reader.onloadend = function (e) {
          setNewImage(reader.result);
      }.bind(this);
    }

    const handleSubmit = async(e)=> {
      e.preventDefault();
        
      if (validateFrm()) {
        setSubmitted(true);
        await updateProfile();
      }
    }

    return (
        <>
    <section className="content-header">
        <h1>Update Profile</h1>
    </section>
    <section className="content">
      <div className="row">
        <div className="col-md-12">
          <div className="box box-primary">
            <div className="box-header with-border">
                <h3 className="box-title">Profile</h3>
            </div>
            <div className="box-body">
              <div className="x_content">
                <form method="post" className="form-horizontal form-label-left" onSubmit={handleSubmit}>
                  <div className="box-body">
                    <div className="input required">
                      <div className={errors.first_name ? "form-group has-error":"form-group"}>
                        <label className="control-label col-md-3 col-sm-3 col-xs-12" for="first_name">First Name</label>
                        <div className="col-md-6 col-sm-6 col-xs-12">
                          <input type="text" name="first_name" value={first_name} placeholder="First Name" required="required" className="form-control col-md-7 col-xs-12" id="first_name" onChange={handleChange}/>
                          {errors.first_name && <span className="help-block">{errorMsg.first_name}.</span>}
                        </div>
                      </div>
                    </div>
                    <div className="input required">
                      <div className={errors.last_name ? "form-group has-error":"form-group"}>
                        <label className="control-label col-md-3 col-sm-3 col-xs-12" for="last_name">Last Name</label>
                          <div className="col-md-6 col-sm-6 col-xs-12">
                            <input type="text" name="last_name" value={last_name} placeholder="Last Name" required="required" className="form-control col-md-7 col-xs-12" id="last_name" onChange={handleChange}/>
                            {errors.last_name && <span className="help-block">{errorMsg.last_name}.</span>}
                          </div>
                        </div>
                      </div>
                      <div className="input required">
                        <div className={errors.phone ? "form-group has-error":"form-group"}>
                          <label className="control-label col-md-3 col-sm-3 col-xs-12" for="phone">Phone</label>
                          <div className="col-md-6 col-sm-6 col-xs-12">
                            <input type="number" name="phone" value={phone} placeholder="Phone Number" required="required" className="form-control col-md-7 col-xs-12" id="phone" onChange={handleChange}/>
                            {errors.phone && <span className="help-block">{errorMsg.phone}.</span>}
                          </div>
                        </div>
                      </div>
                      <div className="input required">
                        <div className={errors.profile_image ? "form-group has-error":"form-group"}>
                          <label className="control-label col-md-3 col-sm-3 col-xs-12" for="profile_image">Profile Image</label>
                          <div className="col-md-6 col-sm-6 col-xs-12">
                            <input type="file" className="form-control" id="image" onChange={onFileChange} ref={ref=> fileInputRef = ref} onClick={e => e.target.value=null}/>
                            {errors.profile_image && <span className="help-block">{errorMsg.profile_image}.</span>}
                          </div>
                        </div>
                      </div>
                      {showImage && 
                        <div className="form-group">
                            <label className="control-label col-md-3 col-sm-3 col-xs-12"></label>
                            <div className="col-sm-7">
                                <img src={imgUrl+'/'+showImage} width="100" height="100"/>
                            </div>
                        </div>}
                    </div>
                    <div className="box-footer">
                      <div className="form-group">
                        <div className="col-md-6 col-sm-6 col-xs-12 col-md-offset-3">
                          <Link to="/dashboard">Cancel</Link>&nbsp;&nbsp;
                          <button className="btn btn-primary" type="submit">{submitted && (<>
                                        <i className="fa fa-spin fa-refresh"></i>&nbsp;&nbsp;
                                    </>)}Update</button>
                        </div>
                      </div>
                    </div>
                </form>   
              </div> 
            </div>                   
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default Profile;