import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { capitalize,activeMenuOption,callService,pageLoadOption,alertOption,getDate,objectToQueryString } from '../../Utils';
import { API_URL } from '../../constants/api';
import { PAGES_Title } from '../../constants';

const User = (props) => {
    let title = (props.match.params.type );
    const role = props.match.params.type=='trainer' ? 1 : 2 ;
    const [pageLoad, setPageLoad] = useState(true);
    const [users, setUsers] = useState([]);
    const [pagination,setPagination] = useState({pageCount:0,initialPage:0});
    
    useEffect(async()=>{
        await fetchData();
        activeMenuOption.setPageTitle("Manage "+(props.match.params.type=='trainer'?"Trainer":"Trainee"));//activeMenu
    },[props.match.params.type])

    const deleteItem = async({id,created},type=1)=>{
        let utype = type==1 ? `Trainer` : `Trainee` ;
        let msg = `Are you sure to delete the ${utype}?`;
        if(window.confirm(msg)){
            pageLoadOption.load(true);
            const response = await callService(`${API_URL.USER_API}${id}?create=${created}`,'DELETE');    
            if(response.success !== undefined && response.success){
                await fetchData();
                alertOption.setMsg({show:true,type:'success',message:response.message});
            }else{
                //set alert message info
                alertOption.setMsg({show:true,type:'error',message:response.message});
            }
            setFalseAll();
        }
    }
    const statusUpdate = async({id,created,status},type=0)=>{
        let msg = status ? `Are you sure to deactivate the item?` : `Are you sure to activate the item?`;
        if(window.confirm(msg)){
            pageLoadOption.load(true);
            let url = `${API_URL.USER_UPDATE}${id}`;
            let method = 'PATCH';
            let params = {created:created,status: status ? 0 : 1};
            const response = await callService(url,method,params);    
            if(response.success !== undefined && response.success){
                users.map(item=>{
                    if(item.id == id){
                        item.profile_status = !status; 
                    }
                    return item;
                });
                alertOption.setMsg({show:true,type:'success',message:response.message});
            }else{
                //set alert message info
                alertOption.setMsg({show:true,type:'error',message:response.message});
            }
            setFalseAll();
        }
    }
    const setFalseAll = ()=>{
        setPageLoad(false);
        pageLoadOption.load(false);
    }
    const fetchData = async(queryString = {}) =>{
        pageLoadOption.load(true);
        let url = `${API_URL.USER_LIST_API}${props.match.params.type}` + '';
        let qryString = {};
        if(Object.keys(queryString).length>0){
            qryString = objectToQueryString(queryString);
            url += '?'+qryString;
        }
        updateHistory(qryString)
        const response = await callService(url,'GET');
        //console.log(response,"api user list get ");
        if(typeof response !== undefined && typeof response.success !== undefined && response.success){
        //if(response.success !== undefined && response.success){
            const {items,Pagination} = response.data;
            setUsers(items);
            setPagination({...pagination,pageCount:Pagination.last_page,initialPage:(Pagination.current_page-1)})
        }
        setFalseAll();
    }
    const updateHistory = (queryString)=>{
        props.history.push({
                            pathname: props.match.url,
                            search: Object.keys(queryString).length>0?"?" + queryString:''
                            })
    }
    const activeMenu = (menu) =>{
        activeMenuOption.setCurrentmenu(menu);
    }
    const nextPageRecord = async(event)=>{
        if(event.selected > 0){
            setPagination({...pagination,initialPage:event.selected})
            await fetchData({page:(event.selected + 1)});
        }else{
            await fetchData();
        }
    }
    
    return (
      <>
    <section className="content-header">
    <h1>Manage { capitalize(title) }</h1>
    </section>
    <section className="content">
    <div className="row">
        <div className="col-md-12">
        <div className="box box-black">
            <div className="box-header with-border">
            <h3 className="box-title">Listing</h3>  
        </div>
        <div className="box-body">
            <div className="table-responsive">
            <table className="table table-striped table-bordered TblAlignedCentered no-margin">
                <thead>
                    <tr>
                        <th width="10%"></th>
                        <th width="5%">#</th>
                        <th width="40%">Details</th>
                        <th width="15%">Status</th>
                        <th width="15%">Email Verified</th>
                        <th width="15%">Created</th>
                    </tr>
                </thead>
                {pageLoad && 
                <tbody>
                <tr>
                    <td colSpan="5" className="text-center">
                        <i className="fa fa-spin fa-refresh"></i>
                    </td>
                </tr>
                </tbody>}
                {!pageLoad && <tbody>
                    {users.length>0  ?
                    users.map((item,key)=>{
                        return (
                            <tr key={key}>
                        <td>
                            <div className="input-group-btn">
                                <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                    Action &nbsp;&nbsp;
                                    <span className="fa fa-caret-down"></span>
                                </button>
                                <ul className="dropdown-menu">
                                    {/* <li>
                                        <Link to={"/category/edit/"+item.id} className="loader-overlay">
                                            Edit
                                        </Link>
                                    </li> */}
                                    {(item.deleted_at == undefined || item.deleted_at == null) && 
                                    <li>
                                        <a href="javascript:void(0);" onClick={async()=>statusUpdate({id:item.id,created:item.created_at,status:item.profile_status},role)} className="loader-overlay">
                                            {item.profile_status?`Deactivate`:`Activate`}
                                        </a>
                                    </li>}
                                    <li>
                                        <Link to={"/user/"+item.id} className="loader-overlay">
                                            View
                                        </Link>
                                    </li>
                                    {/* <li className="divider"></li>
                                    <li>
                                        <a href="javascript:void(0);" onClick={async()=>deleteItem({id:item.id,created:item.created_at},role)} className="loader-overlay">
                                            Delete
                                        </a>
                                    </li> */}
                                </ul>
                            </div>
                        </td>
                        <td>{key+1}</td>
                        <td>
                            <label>Name :</label> {item.user_name}<br/>
                            <label>Email :</label> {item.email}<br/>
                            <label>Phone :</label> {item.phone}<br/>
                            <label>DOB :</label> {item.dob?(getDate(parseInt(item.dob),true,false)):''}
                        </td>
                        <td>
                            {item.deleted_at != undefined && item.deleted_at != null ? 
                                <>
                                <span className="label label-danger">{`Deleted`}</span><br/>
                                {getDate(parseInt(item.deleted_at),true,false,'short')}<br/>{getDate(parseInt(item.deleted_at),false)}
                                </>
                                :
                            <span className={item.profile_status?"label label-success":"label label-danger"}>{item.profile_status?`Activate`:`Deactivate`}</span>
                            }
                        </td>
                        <td>
                            <span className={item.email_verified?"label label-success":"label label-danger"}>{item.email_verified?`Yes`:`No`}</span><br/>
                            
                            {item.email_verified_at != undefined && item.email_verified_at != null ?
                            <>{getDate(parseInt(item.email_verified_at),true,false,'short')}<br/>
                              {getDate(parseInt(item.email_verified_at),false)}</>:''
                            }
                        </td>
                        <td>{getDate(parseInt(item.created_at),true,false,'short')}<br/>{getDate(parseInt(item.created_at),false)}</td>
                    </tr> );
                    }) :
                        <tr>
                         <td colSpan="5" className="text-center">No Record found.</td>
                        </tr>
                    }
                    </tbody>}
                </table>
                </div>
            </div>
            <div className="box-footer clearfix">
                <div className="text-center">
                <div className="paginate-count pull-left">Total Records : {users.length}</div>
                </div>
                {pagination.pageCount > 1 && 
                    <ReactPaginate
                        previousLabel={"« previous"}
                        nextLabel={"next »"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pagination.pageCount}
                        forcePage={pagination.initialPage}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={async(event)=>await nextPageRecord(event)}
                        containerClassName={"pagination pagination-sm no-margin pull-right"}
                        subContainerClassName={""}
                        activeClassName={"active"}/>
                    }
                {/* <ul className="pagination pagination-sm no-margin pull-right">
                    <li><a href="#">«</a></li>
                    <li><a href="#">1</a></li>
                    <li><a href="#">2</a></li>
                    <li><a href="#">3</a></li>
                    <li><a href="#">»</a></li>
                </ul> */}
            </div>
        </div>
        </div>
    </div>
    </section>
    </>
  )
}

export default User