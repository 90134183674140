import { alertOption } from '../../Utils';
const ModalPopup = (props)=>{
    const close = ()=>{
        props.onClose();
        //alertOption.hide();
    }
    const success = ()=>{
        props.onSuccess("testdemo asdasd");
        //alertOption.hide();
    }
    //console.log(props," gopal in modal popup");
	return (
        <>
        {props.show && 
        <div className="modal fade in show" id="modal-default">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=> close()}>
                            <span aria-hidden="true">×</span>
                        </button>
                        <h4 className="modal-title"></h4>
                    </div>
                    <div className="modal-body">
                        {props.children}
                    </div>
                    {/*<div className="modal-footer">
                                            <button type="button" className="btn btn-default pull-left" data-dismiss="modal" onClick={()=> close()}>Close</button>
                                            <button type="button" className="btn btn-primary" onClick={()=> success()}>Save changes</button>
                                        </div>*/}
                </div>
            </div>
        </div>}
        </>
	)	
}
export default ModalPopup;